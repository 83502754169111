import React, { useState, useEffect } from "react";
import openSocket from "socket.io-client";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import axios from 'axios';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";

const baseUrl = process.env.REACT_APP_BACKEND_CHATBOT_URL;

// const baseUrl = "http://localhost:8081"

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(8, 8, 3),
	},

	paper: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		marginBottom: 12,

	},

	settingOption: {
		marginLeft: "auto",
	},
	margin: {
		margin: theme.spacing(1),
	},

}));

const Settings = () => {
	const classes = useStyles();

	const [settings, setSettings] = useState([]);
	const [campWhatsApp, setCampWhatsApp] = useState("");
  	const [campUser, setCampUser] = useState("");

	useEffect(() => {
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/settings");
				setSettings(data);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, []);

	useEffect(() => {
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

		socket.on("settings", data => {
			if (data.action === "update") {
				setSettings(prevState => {
					const aux = [...prevState];
					const settingIndex = aux.findIndex(s => s.key === data.setting.key);
					aux[settingIndex].value = data.setting.value;
					return aux;
				});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	const handleChangeSetting = async e => {
		const selectedValue = e.target.value;
		const settingKey = e.target.name;

		try {
			await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});
			toast.success(i18n.t("settings.success"));
		} catch (err) {
			toastError(err);
		}
	};

	const getSettingValue = key => {
		const { value } = settings.find(s => s.key === key);
		return value;
	};

	// const getLimitValue = () => {
	// 	const url = baseUrl+"/limite/list"
    // 	axios.get(url)
	// 	.then(res=>{
	// 		if (res.data.success) {
	// 			const data = res.data.data[0]
	// 			setCampWhatsApp(data.whatsapp)
	// 			setCampUser(data.user)
	// 		}
	// 		else {
	// 			alert("Error web service")
	// 		}
	// 	})
	// 	.catch(error=>{
	// 		alert("Error server "+error)
	// 	})
	// };

	useEffect(() => {
		const fetchLimit = async () => {
			try {
				const { data } = await axios.get(baseUrl+"/limite/list");
				setCampWhatsApp(data.data[0].whatsapp)
				setCampUser(data.data[0].user)
				console.log(data.data[0])
			} catch (err) {
				toastError(err);
			}
		};
		fetchLimit();
	}, []);

	const handleChangeLimite = async () => {
		const datapost = {
		  whatsapp: campWhatsApp,
		  user: campUser,
		};
	
		try {
		  const { data } = await axios.get(baseUrl + "/limite/list");
		  await axios.post(baseUrl + "/limite/update/" + data.data[0].id, datapost);
		  toast.success(i18n.t("settings.success"));
		} catch (err) {
		  toastError(err);
		}
	  };

	return (
		<div className={classes.root}>
			<Container className={classes.container} maxWidth="sm">
				<Typography variant="body2" gutterBottom>
					{i18n.t("settings.title")}
				</Typography>
				<Paper className={classes.paper}>
					<Typography variant="body1">
						{i18n.t("settings.settings.userCreation.name")}
					</Typography>
					<Select
						margin="dense"
						variant="outlined"
						native
						id="userCreation-setting"
						name="userCreation"
						value={
							settings && settings.length > 0 && getSettingValue("userCreation")
						}
						className={classes.settingOption}
						onChange={handleChangeSetting}
					>
						<option value="enabled">
							{i18n.t("settings.settings.userCreation.options.enabled")}
						</option>
						<option value="disabled">
							{i18n.t("settings.settings.userCreation.options.disabled")}
						</option>
					</Select>

				</Paper>

				<Paper className={classes.paper}>
					<TextField
						id="api-token-setting"
						readonly
						label="Token Api"
						margin="dense"
						variant="outlined"
						fullWidth
						value={settings && settings.length > 0 && getSettingValue("userApiToken")}
					/>
				</Paper>

				<Paper className={classes.paper}>
				<Typography variant="body1">
					{i18n.t("settings.settings.user")}
				</Typography>
				<TextField
					id="camp-user"
					label="Usuários"
					margin="dense"
					variant="outlined"
					fullWidth
					value={campUser}
					onChange={(e) => setCampUser(e.target.value)}
				/>
				<CheckCircleIcon style={{ cursor: "pointer", marginLeft: "8px", color: "green" }} onClick={handleChangeLimite} />
				</Paper>

				<Paper className={classes.paper}>
				<Typography variant="body1">
					{i18n.t("settings.settings.whatsapp")}
				</Typography>
				<TextField
					id="camp-whatsapp"
					label="Conexões"
					margin="dense"
					variant="outlined"
					fullWidth
					value={campWhatsApp}
					onChange={(e) => setCampWhatsApp(e.target.value)}
				/>
				<CheckCircleIcon style={{ cursor: "pointer", marginLeft: "8px", color: "green" }} onClick={handleChangeLimite} />
				</Paper>

			</Container>
		</div>
	);
};

export default Settings;
